//
// user.js
// Use this to write your custom JS
//
const form = document.querySelector('form');

form.onsubmit = e => {
  e.preventDefault();

  // Prepare data to send
  const data = {};
  const formElements = Array.from(form);
  formElements.map(input => {
    if ((input.type == 'radio' || input.type == 'checkbox') && input.checked) {
      data[input.name] = input.value
    } else if ((input.type != 'radio' && input.type != 'checkbox') && input.name !== '') {
      data[input.name] = input.value
    }
  });

  // Construct an HTTP request
  let xhr = new XMLHttpRequest();
  xhr.open(form.method, form.action, true);

  // Send the collected data as JSON
  xhr.send(JSON.stringify(data));

  // Callback function
  xhr.onloadend = response => {
    if (response.target.status === 200) {
      // The form submission was successful
      window.location.href = "thankyou.html"
    } else {
      // The form submission failed
      // Static Site Mailer returns a JSON object with error messages on
      JSON.parse(response.target.response).message
      console.error(JSON.parse(response.target.response).message);
    }
  };
}